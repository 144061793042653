import {
	baseFormattedDate,
	Button,
	DatePicker,
	FilterExpanded,
	Form,
	formatAmount,
	Page,
	Table,
	useLazyQuery,
	useMyUrl,
	useQuery,
	useTranslation,
	Widget,
	Zone
} from '@gimlite/watermelon'
import { useEffect, useMemo, useState } from 'react'
import {
	Collect,
	Pagination,
	Query,
	QueryCollectsArgs,
	QueryCsvCollectsArgs
} from '../../../../client/graphql'
import { collectsGql } from '../gql/collects.gql'
import { csvCollectsGql } from '../gql/csv-collects.gql'
import { CollectDrawer } from './collect-drawer.component'

type SearchParams = {
	startDate?: string
	stopDate?: string
	limit?: number
	page?: number
}

type RenderedCollect = Collect & {
	siteName: string | null
	city: string | null
	date: string | null
	cardComment: string | null
	chfAmount: number
	eurAmount: number
	tokAmount: number
	defaultCurrency: 'EUR' | 'CHF' | null
}

export const CollectsPage = () => {
	const { lang } = useTranslation()
	const { getParamsUrl, setParamsUrl } = useMyUrl()
	const [selectedCollectId, setSelectedCollectId] = useState<string | null>(
		null
	)

	const [csvCollectsCall, csvCollectsState] = useLazyQuery<
		{ csvCollects: Query['csvCollects'] },
		QueryCsvCollectsArgs
	>(csvCollectsGql, {
		notification: {
			success: 'The CSV file is being downloaded',
			error: 'Your CSV export failed'
		}
	})

	const collectsState = useQuery<
		{ collects: Query['collects'] },
		QueryCollectsArgs
	>(collectsGql, {
		variables: { limit: 20, ...getParamsUrl },
		pollInterval: 1000 * 60
	})

	const collects = useMemo(():
		| { list: RenderedCollect[]; paging: Pagination }
		| undefined => {
		if (!collectsState.data?.collects) return undefined

		const { list, paging } = collectsState.data.collects

		return {
			list: list.map(item => ({
				...item,
				defaultCurrency: (item.site?.defaultCurrency || null) as
					| 'EUR'
					| 'CHF'
					| null,
				date: item.logDate || null,
				siteName: item.site?.name || null,
				city: item.site?.city || null,
				cardComment: item.serviceCard?.comment || null,
				chfAmount:
					item?.currencyAmounts?.find(({ currency }) => currency === 'CHF')
						?.foreignAmount || 0,
				eurAmount:
					item?.currencyAmounts?.find(({ currency }) => currency === 'EUR')
						?.foreignAmount || 0,
				tokAmount:
					item?.currencyAmounts?.find(({ currency }) => currency === 'TOK')
						?.foreignAmount || 0
			})),
			paging: paging
		}
	}, [collectsState.data?.collects])

	useEffect(() => {
		if (csvCollectsState.data) {
			window.open(csvCollectsState.data.csvCollects.downloadURL, '_blank')
		}
	}, [csvCollectsState.data])

	return (
		<Page>
			<Widget
				state={{
					loading: collectsState.loading,
					error: collectsState.error,
					refetch: collectsState.refetch
				}}
				config={{ title: 'Rapports de collecte', subtitle: 'Module Finances' }}
			>
				<Zone
					config={{
						zones: [['filter'], ['table']],
						rows: ['min-content', '1fr'],
						columns: ['1fr']
					}}
				>
					<Zone.Area config={{ area: 'filter' }}>
						<FilterExpanded
							data={{
								value: {
									time:
										getParamsUrl?.startDate && getParamsUrl?.stopDate
											? [getParamsUrl.startDate, getParamsUrl.stopDate]
											: []
								}
							}}
							handleEvent={{
								submit: data => {
									let params: SearchParams = {}
									if (data.time) {
										params.startDate = data.time[0]
										params.stopDate = data.time[1]
									}

									setParamsUrl({
										...getParamsUrl,
										...params
									})
								}
							}}
						>
							<FilterExpanded.Fields>
								<Form.Item
									config={{
										name: 'time',
										label: 'Date'
									}}
								>
									<DatePicker
										config={{ mode: 'range', format: 'date', clear: true }}
									/>
								</Form.Item>
							</FilterExpanded.Fields>

							<FilterExpanded.Actions>
								<Button
									config={{
										text: 'Rechercher',
										type: { value: 'submit' }
									}}
								/>

								<Button
									handleEvent={{
										click: () => {
											setParamsUrl({})
										}
									}}
									config={{
										text: 'Nettoyer',
										disabled: !(
											getParamsUrl && Object.values(getParamsUrl).length > 0
										)
									}}
								/>

								<Button
									handleEvent={{
										click: async () => {
											try {
												await csvCollectsCall({
													variables: {
														...getParamsUrl,
														targetTZ:
															Intl.DateTimeFormat().resolvedOptions().timeZone
													}
												})
											} catch (error) {
												console.error(error)
											}
										}
									}}
									config={{
										text: 'CSV',
										icon: 'faFileDownloadSolid'
									}}
								/>
							</FilterExpanded.Actions>
						</FilterExpanded>
					</Zone.Area>

					<Zone.Area config={{ area: 'table' }}>
						<>
							{collects &&
								collects?.list?.map(c => (
									<CollectDrawer
										collect={c}
										isOpen={selectedCollectId === c._id}
										onClose={() => setSelectedCollectId(null)}
									/>
								))}

							<Table<RenderedCollect>
								handleEvent={{
									paging: (value: any) =>
										setParamsUrl({ ...getParamsUrl, ...value }),
									read: (value: any) => setSelectedCollectId(value._id)
								}}
								config={{
									pagination: 'click',
									columns: [
										{
											key: 'cid',
											title: 'No de collecte',
											sort: {
												sorter: (a, b) =>
													`${a.cid || null}`.toLowerCase() >
													`${b.cid || ''}`.toLowerCase()
														? -1
														: 1
											}
										},
										{
											key: 'cardComment',
											title: 'Carte de collecte',
											sort: {
												sorter: (a, b) =>
													`${a.cardComment || ''}`.toLowerCase() >
													`${b.cardComment || ''}`.toLowerCase()
														? -1
														: 1
											}
										},
										{
											key: 'date',
											title: 'Date de collecte',
											sort: {
												sorter: (a, b) =>
													(a.date
														? new Date(b.date as unknown as any).getTime()
														: 0) -
													(b.date
														? new Date(a.date as unknown as any).getTime()
														: 0),
												defaultSortOrder: 'ascend'
											},
											render: value =>
												value.date
													? baseFormattedDate({ date: value.date, lang })
													: 'Non définie'
										},
										{
											key: 'siteName',
											title: 'Automate',
											sort: {
												sorter: (a, b) =>
													`${a.siteName || ''}`.toLowerCase() >
													`${b.siteName || ''}`.toLowerCase()
														? -1
														: 1
											}
										},
										{
											key: 'localAmount',
											title: 'Montant total',
											sort: {
												sorter: (a, b) =>
													(b.localAmount || 0) - (a.localAmount || 0)
											},
											render: value =>
												formatAmount({
													amount: value.localAmount || 0,
													currency: value?.defaultCurrency || null,
													lang
												})
										},
										{
											key: 'chfAmount',
											title: 'Montant (CHF)',
											sort: {
												sorter: (a, b) =>
													(b.chfAmount || 0) - (a.chfAmount || 0)
											},
											render: value =>
												formatAmount({
													amount: value.chfAmount || 0,
													currency: 'CHF',
													lang
												})
										},
										{
											key: 'eurAmount',
											title: 'Montant (EUR)',
											sort: {
												sorter: (a, b) =>
													(b.eurAmount || 0) - (a.eurAmount || 0)
											},
											render: value =>
												formatAmount({
													amount: value.eurAmount || 0,
													currency: 'EUR',
													lang
												})
										},
										{
											key: 'eurAmount',
											title: 'Montant (TOK)',
											sort: {
												sorter: (a, b) =>
													(b.eurAmount || 0) - (a.eurAmount || 0)
											},
											render: value =>
												formatAmount({
													amount: value.tokAmount || 0,
													currency: null,
													lang
												})
										}
									],
									actions: { read: true }
								}}
								data={{
									list: collects?.list || [],
									paging: collects?.paging || {
										count: 0,
										current: 1,
										limit: 10
									}
								}}
							/>
						</>
					</Zone.Area>
				</Zone>
			</Widget>
		</Page>
	)
}
