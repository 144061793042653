import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  PaginationClick,
  PaginationScroll,
  PagingInType,
  PagingOutType,
} from '../../components/pagination/pagination.component';
import { useTranslation } from '../useTranslation.hook';
import './usePagination.hook.scss';

export declare namespace UsePaginationType {
  type Props<T> = {
    dataList?: Array<T>;
    dataPaging?: PagingInType;
    pagination: 'click' | 'scroll' | 'none';
    paging?: UsePaginationType.HandleEvent.Paging['function'];
    render?: (value: Array<T>) => Array<T>;
    formatter?: (value: Array<T>) => Array<T>;
  };

  namespace HandleEvent {
    type Paging = {
      value1: PagingOutType;
      return: void;
      function: (
        value: UsePaginationType.HandleEvent.Paging['value1'],
      ) => UsePaginationType.HandleEvent.Paging['return'];
    };
  }
}

export function usePagination<T extends { _id: string }>({
  dataList,
  dataPaging,
  pagination,
  paging,
  render,
  formatter,
}: UsePaginationType.Props<T>) {
  const { t, lang } = useTranslation();

  const [saveData, setSaveData] =
    useState<UsePaginationType.Props<T>['dataList']>();

  const [saveDataRender, setSaveDataRender] =
    useState<UsePaginationType.Props<T>['dataList']>();

  const [maxPage, setMaxPage] = useState<number>(1);

  const [containScroll, setContainScroll] = useState<HTMLElement | null>(null);
  const [nodeScroll, setNodeScroll] = useState<React.ReactNode | undefined>(
    undefined,
  );

  const listContainRef = useCallback(
    (node: HTMLElement | undefined | null) => {
      if (node && pagination === 'scroll') {
        setContainScroll(() => node);
      }

      if (pagination === 'scroll' && dataPaging?.current === 1 && node) {
        node?.scroll(0, 0);
      }
    },
    [dataPaging],
  );

  const dataListFormatted = useMemo(() => {
    if (!dataList) return undefined;
    return formatter ? formatter(dataList) : dataList;
  }, [dataList]);

  const dataListIsEmpty = useMemo(() => {
    return !!(!dataListFormatted || dataListFormatted?.length === 0);
  }, [dataListFormatted]);

  const nodePagination = useMemo(() => {
    return pagination == 'click' && dataPaging ? (
      <div className="usePagination__pagination usePagination__pagination--click">
        <PaginationClick
          handleEvent={{
            paging: (value: PagingOutType) => paging?.(value),
          }}
          data={dataPaging}
          config={{ size: true }}
        />
      </div>
    ) : pagination == 'scroll' && containScroll && dataPaging ? (
      <div className="usePagination__pagination usePagination__pagination--scroll">
        <PaginationScroll
          handleEvent={{
            paging: (value: PagingOutType) => {
              if (value.page === 1) {
                setSaveData(() => []);
                setSaveDataRender(() => []);
              }

              paging?.(value);
            },
            component: (component: React.ReactNode) => setNodeScroll(component),
          }}
          config={{
            scroll: {
              target: containScroll,
              total: saveData ? saveData.length : 0,
            },
          }}
          data={dataPaging}
        />
      </div>
    ) : null;
  }, [dataPaging, containScroll, paging, pagination, saveData]);

  useEffect(() => {
    if (dataPaging?.current && dataPaging?.current === 1 && maxPage !== 1) {
      setMaxPage(() => 1);
      paging?.({
        page: 1,
        limit: dataPaging?.limit,
      });
      return;
    }

    if (dataListFormatted) {
      if (pagination === 'click' || pagination === 'none') {
        setSaveData(() => [...dataListFormatted]);
      } else if (pagination === 'scroll') {
        if (dataPaging?.current === 1) {
          setSaveData(() => [...dataListFormatted]);
        } else {
          setMaxPage((current) => current + 1);
          setSaveData((currentData) => {
            if (currentData && Array.isArray(currentData)) {
              return [
                ...currentData.filter(
                  (item) =>
                    !dataListFormatted.some((df) => df._id === item._id),
                ),
                ...dataListFormatted,
              ];
            } else {
              return [...dataListFormatted];
            }
          });
        }
      }
    }
  }, [dataPaging, dataListFormatted]);

  useEffect(() => {
    if (pagination === 'none' && dataListFormatted) {
      setSaveData(() => dataListFormatted);
    }
  }, [dataListFormatted, setSaveData]);

  useEffect(() => {
    return () => {
      setSaveData(() => []);
      setSaveDataRender(() => []);
    };
  }, [setSaveData, setSaveDataRender]);

  useEffect(() => {
    if (render && saveData) setSaveDataRender(() => render(saveData));
  }, [setSaveDataRender, render, saveData, lang]);

  return {
    saveData: saveDataRender || saveData,
    dataListIsEmpty,
    listContainRef,
    setSaveData,
    nodeScroll:
      nodeScroll && !dataListIsEmpty && pagination === 'scroll' ? (
        <div className="usePagination__scroll">
          <div className="usePagination__scroll__item">{nodeScroll}</div>
        </div>
      ) : null,
    nodePagination,
  };
}
