import { useRootStore, useTranslation } from '@gimlite/watermelon'
import IEM from '@gimlite/watermelon/assets/logo/IEM_small'
import { Main } from '@gimlite/watermelon/components/main/main.component'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const MainUse = observer(() => {
	const { SSOStore, I18NStore } = useRootStore()
	const navigate = useNavigate()
	const { t, lang } = useTranslation()
	const me = SSOStore.user

	useEffect(() => {
		I18NStore.updateLang('fr_FR')
	}, [])

	return me ? (
		<Main
			handleEvent={{
				selected: key => navigate(key)
			}}
			config={{
				navigation: {
					mode: 'drop'
				},
				//! Lors d'une refact du router, ce code apparaitra une fois en global (PROBLEM 1)
				//******* START ************/
				header: {
					logo: IEM()
				}
				//******* STOP ************/
			}}
		/>
	) : null
})

export default MainUse
