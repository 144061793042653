import {
	AppLoaderEnd,
	AppLoaderStart,
	ConfigAnt,
	ConfigApp,
	ConfigGQL,
	ConfigI18N,
	ConfigMenu,
	ConfigNotification,
	ConfigRoot,
	ConfigSSO,
	ConfigTheme,
	ConfigWizard,
	Keycloak
} from '@gimlite/watermelon'
import { createRoot } from 'react-dom/client'
import { CustomRouter } from './CustomRouter'
import IEMSmallLogo from './assets/logo/IEM_small'
import config from './config'

const root = createRoot(document.getElementById('root') as HTMLElement)

const { ENV, VERSION_FRONT, BFF_HOST, BFF_WS_HOST } = config

const defaultTheme = 'light'

root.render(
	<ConfigRoot>
		<AppLoaderStart config={{ logo: IEMSmallLogo(), defaultTheme }}>
			<ConfigApp
				debug={false}
				version={{
					env: config?.ENV,
					front: VERSION_FRONT
				}}
				favicon={
					'https://cellar-c2.services.clever-cloud.com/yoonite-resources/default/iem.svg'
				}
			>
				<ConfigGQL
					uri={BFF_HOST}
					headers={{
						'x-access-token': config.BFF_TOKEN
					}}
				>
					<ConfigSSO
						mode={{ type: 'children', parentUrl: config.SSO_PARENT_URL }}
						instance={
							new Keycloak({
								url: config.SSO_URL,
								realm: config.SSO_REALM,
								clientId: config.SSO_CLIENT_ID
							})
						}
					>
						<ConfigI18N>
							<ConfigWizard registry={[]}>
								<ConfigMenu>
									<ConfigNotification>
										<ConfigTheme
											color={{
												dark: '#5dbed2',
												light: '#0a2652'
											}}
											defaultTheme={defaultTheme}
										>
											<ConfigAnt>
												<AppLoaderEnd>
													<CustomRouter />
												</AppLoaderEnd>
											</ConfigAnt>
										</ConfigTheme>
									</ConfigNotification>
								</ConfigMenu>
							</ConfigWizard>
						</ConfigI18N>
					</ConfigSSO>
				</ConfigGQL>
			</ConfigApp>
		</AppLoaderStart>
	</ConfigRoot>
)
