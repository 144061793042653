import {
	baseFormattedDate,
	Blurb,
	Description,
	Drawer,
	formatAmount,
	Languages,
	Space,
	useTranslation,
	Widget,
	Zone
} from '@gimlite/watermelon'
import { Collect } from '../../../../client/graphql'

export type CollectDrawerProps = {
	isOpen: boolean
	onClose: () => void
	collect: Collect
}

const coinsTranslations = new Map<string, string>([
	['EU005A', '0.05 €'],
	['EU010A', '0.10 €'],
	['EU020A', '0.20 €'],
	['EU050A', '0.50 €'],
	['EU100A', '1.00 €'],
	['EU200A', '2.00 €'],
	['CH005A', '0.05 CHF'],
	['CH010A', '0.10 CHF'],
	['CH020A', '0.20 CHF'],
	['CH050A', '0.50 CHF'],
	['CH100A', '1.00 CHF'],
	['CH200A', '2.00 CHF'],
	['CH500A', '5.00 CHF'],
	['TK001A', '1 TOK']
])

const renderCollectDetails = (c: Collect, lang: Languages | null) => {
	if (!c.site) return undefined
	const { site } = c
	const { name, address1, zipcode, city } = site

	const eurAmountDefined = c?.currencyAmounts?.some(
		({ currency }) => currency === 'EUR'
	)
	const chfAmountDefined = c?.currencyAmounts?.some(
		({ currency }) => currency === 'CHF'
	)
	const tokAmountDefined = c?.currencyAmounts?.some(
		({ currency }) => currency === 'TOK'
	)

	return (
		<Widget
			config={{
				title: `Collecte ${c.cid}`,
				subtitle: c.logDate
					? baseFormattedDate({ date: c.logDate, lang })
					: undefined
			}}
		>
			<Zone
				config={{
					zones: [['site'], ['money']],
					rows: ['min-content', 'min-content'],
					columns: ['1fr'],
					gap: { y: 5 }
				}}
			>
				<Zone.Area config={{ area: 'site' }}>
					<Description
						data={[
							{
								key: 'card',
								label: 'Carte de collecte',
								value: c?.serviceCard?.comment || c.cardUID
							},
							{
								key: 'name',
								label: 'Nom du site',
								value: name
							},
							{
								key: 'address1',
								label: 'Adresse',
								value: address1
							},
							{
								key: 'zipcode',
								label: 'Code postal',
								value: zipcode
							},
							{
								key: 'city',
								label: 'Ville',
								value: city
							},
							{
								key: 'trxCount',
								label: 'Nombre de transactions',
								value: c.trxCount
							}
						]}
						config={{
							layout: 'column',
							way: 'horizontal',
							size: 'medium',
							mode: 'line'
						}}
					/>
				</Zone.Area>

				<Zone.Area config={{ area: 'money' }}>
					{c.currencyAmounts && c.coins ? (
						<Zone
							config={{
								zones: [['chf', 'eur', 'tok']],
								rows: ['min-content'],
								columns: ['1fr', '1fr', '1fr']
							}}
						>
							<Zone.Area config={{ area: 'eur' }}>
								{eurAmountDefined && (
									<>
										<Blurb
											data={{
												title: 'Détail tirelire €',
												description: eurAmountDefined
													? formatAmount({
															amount:
																c.currencyAmounts.find(
																	({ currency }) => currency === 'EUR'
																)?.foreignAmount || 0,
															currency: 'EUR',
															lang
														})
													: 'Non définie'
											}}
										/>
										<Space config={{ count: 1 }} />
										<Description
											data={
												!Array.isArray(c.coins) && typeof c.coins === 'object'
													? Object.entries(c.coins)
															.filter(([currency]) => currency.startsWith('EU'))
															.map(([currency, amount]) => ({
																key: currency,
																label:
																	coinsTranslations.get(currency) || currency,
																value: amount as number
															}))
													: []
											}
											config={{
												layout: 'column',
												way: 'horizontal',
												size: 'medium',
												mode: 'line'
											}}
										/>
									</>
								)}

								{!eurAmountDefined && <></>}
							</Zone.Area>

							<Zone.Area config={{ area: 'chf' }}>
								{chfAmountDefined && (
									<>
										<Blurb
											data={{
												title: 'Détail tirelire CHF',
												description: chfAmountDefined
													? formatAmount({
															amount:
																c.currencyAmounts.find(
																	({ currency }) => currency === 'CHF'
																)?.foreignAmount || 0,
															currency: 'CHF',
															lang
														})
													: 'Non définie'
											}}
										/>
										<Space config={{ count: 1 }} />
										<Description
											data={
												!Array.isArray(c.coins) && typeof c.coins === 'object'
													? Object.entries(c.coins)
															.filter(([currency]) => currency.startsWith('CH'))
															.map(([currency, amount]) => ({
																key: currency,
																label:
																	coinsTranslations.get(currency) || currency,
																value: amount as number
															}))
													: []
											}
											config={{
												layout: 'column',
												way: 'horizontal',
												size: 'medium',
												mode: 'line'
											}}
										/>
									</>
								)}

								{!chfAmountDefined && <></>}
							</Zone.Area>

							<Zone.Area config={{ area: 'tok' }}>
								{tokAmountDefined && (
									<>
										<Blurb
											data={{
												title: 'Détail tirelire TOK',
												description: chfAmountDefined
													? formatAmount({
															amount:
																c.currencyAmounts.find(
																	({ currency }) => currency === 'TOK'
																)?.foreignAmount || 0,
															currency: null,
															lang
														})
													: 'Non définie'
											}}
										/>
										<Space config={{ count: 1 }} />
										<Description
											data={
												!Array.isArray(c.coins) && typeof c.coins === 'object'
													? Object.entries(c.coins)
															.filter(([currency]) => currency.startsWith('TK'))
															.map(([currency, amount]) => ({
																key: currency,
																label:
																	coinsTranslations.get(currency) || currency,
																value: amount as number
															}))
													: []
											}
											config={{
												layout: 'column',
												way: 'horizontal',
												size: 'medium',
												mode: 'line'
											}}
										/>
									</>
								)}

								{!chfAmountDefined && <></>}
							</Zone.Area>
						</Zone>
					) : (
						<></>
					)}
				</Zone.Area>
			</Zone>
		</Widget>
	)
}

export function CollectDrawer({
	isOpen,
	onClose,
	collect
}: CollectDrawerProps) {
	const { t, lang } = useTranslation()
	return (
		<Drawer
			key={collect._id}
			config={{ isOpen }}
			data={renderCollectDetails(collect, lang)}
			handleEvent={{
				close: onClose
			}}
		/>
	)
}
